.header {
    background-color: var(--colorEbonyClay);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 58px 0 46px;
    height: 110px;
    color: white;
    position: relative;

    &__logo {
        position: absolute;
        top: 50%;
        right: 50%;
        margin-top: -28.5px;
        margin-right: -61px;
    }

    &__demo__button {
        display: flex;
        align-items: center;
        gap: 13px;
        font-size: 18px;
        font-weight: 700;
        color: white;
    }

    &__right__section {
        display: flex;
        align-items: center;
        gap: 51px;
    }

    &__lang__section {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 300;
        font-size: 14px;
    }

    &__lang__button {
        color: rgba(255, 255, 255, 0.5);
        &.active {
            font-weight: 700;
            color: var(--colorYellow);
        }
    }

    &__slice {
        background-color: var(--colorEbonyClay);
        position: absolute;
        width: 100%;
        left: 0;
        top: 110px;
        z-index: 999;
        padding-top: 80px;
        padding-bottom: 70px;

        &__content {
            display: flex;
            justify-content: space-between;
        }

        &__left {
            max-width: 941px;
            h1 {
                font-size: 40px;
                line-height: 55px;
                margin-bottom: 80px;
                .highlight-text-yellow {
                    color: var(--colorEbonyClay);
                }
            }
        }

        &__row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__card {
            display: flex;
            gap: 27px;
            width: 45%;
            margin-bottom: 70px;

            &__title:hover {
                cursor: pointer;
            }

            &__tag {
                line-height: 36px;
                font-size: 10px;
                font-weight: 700;
                background-color: var(--colorOrangeTwo);
                color: white;
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 28px;
                border-radius: 20px;
            }

            &__image-box {
                min-width: 60px;
                position: relative;

                img {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }

            h2 {
                font-size: 24px;
                margin-bottom: 0;
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            max-width: 281px;
            align-items: center;

            a {
                text-decoration: none;
            }
        }

        &__login {
            padding: 23px 0 25px;
            margin-bottom: 65px;
            width: 183px;
            background-color: rgba(250, 255, 220, .1);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            &-bg img {
                max-width: 100%;
            }

            h2 {
                font-size: 16px;
                line-height: 18px;
                color: #fff;
                margin-bottom: 0;
                font-weight: 400;
                span {
                    font-size: 20px;
                    line-height: 23px;
                    font-weight: 700;
                }
            }
        }

        &__small-title {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 17px;
            opacity: 0.3;
        }

        &__menu {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: 52px;

            a {
                line-height: 45px;
                color: #fff;
                font-size: 24px;
            }
        }

        &__socials {
            display: flex;
            flex-direction: column;
            text-align: center;

            &__list {
                display: flex;
                gap: 23px;
            }
        }
    }

    button {
        background: 0;
        outline: none;
        margin: 0;
        padding: 0;
        border: 0;
        cursor: pointer;
    }
}

@media screen and (max-width: 1200px) {
    .header {
        &__slice {
            &__left {
                max-width: 700px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .header {
        &__slice {
            &__content {
                gap: 60px;
            }

            &__left {
                max-width: none;
            }

            &__card {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .header {
        padding: 0 12px 0 12px;

        &__demo__button {
            display: none;
        }

        &__slice {
            padding: 40px 0;

            &__content {
                flex-direction: column;
                align-items: center;
            }
        }

        &__logo {
            margin-right: -118px;
        }
    }
}

@media screen and (max-width: 400px) {
    .header {
        &__logo {
            margin-right: -100px;
            margin-top: -24px;
            img {
                width: 200px;
            }
        }
    }
}
