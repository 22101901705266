.footer {
    background-color: var(--colorEbonyClay);
    padding: 83px 0 71px 0;

    &__wrapper {
        display: flex;
        margin-bottom: 80px;
        gap: 164px;
    }

    &__logo__section {
        display: flex;
        flex-direction: column;
        gap: 31px;
    }

    &__socials {
        display: flex;
        gap: 35px;
    }

    &__navigation {
        display: flex;
        gap: 127px;

        a {
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            text-decoration: none;
            line-height: 18px;
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
    }

    &__copyright {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-weight: 400;
    }
}

@media screen and (max-width: 1418px) {
    .footer {
        &__navigation {
            gap: 0;
            justify-content: space-between;
            flex: 1;
        }

        &__wrapper {
            gap: 140px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        &__navigation {
            gap: 60px;
            flex-flow: wrap;
        }

        &__wrapper {
            gap: 100px;
        }
    }
}

@media screen and (max-width: 772px) {
    .footer {
        &__wrapper {
            gap: 60px;
            flex-direction: column;
        }
    }
}
